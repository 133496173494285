import React, { useState, useEffect } from "react"
import styled from 'styled-components';
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/Layout"
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Seo from "../components/seo"
import { Section } from "../components/section";
import { Title2 as DefaultTitle2, TitleWrapper } from "../components/Headings";
import { ContentWrapper } from "../components/common";
import zoomSvg from '../images/svg/expand.svg';

const DATA = ['09', '03', '10', '12', '07', '11', '05', '13', '04', '02', '06', '08', '14', '15', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34'];

const ZoomIcon = styled(zoomSvg)`
  width: 50px;
  height: 50px;
  fill: ${({theme}) => theme.colors.white};
`;

const ZoomIconWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 300ms ease;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  display: block;
`;

const ImageWrapper = styled.div`
  position: relative;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
    transition: all 300ms ease;
    opacity: 0;
  }

  &:hover:before {
    opacity: 1;
  }

  &:hover {
    ${ZoomIconWrapper} {
      opacity: 1;
    }
  }
`;

const Title2 = styled(DefaultTitle2)`
  margin-bottom: 100px;
`;

const Title3 = styled(DefaultTitle2)`
  margin-top: 80px;
  margin-bottom: 30px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 8px;
  row-gap: 8px;

  @media(min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  @media(min-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

function GalleryPage () {
  const [isOpen, setOpen] = useState(false);
  const [selected, setSelected] = useState(0);
  const [images, setImages] = useState([]);

  useEffect(() => {
    setImages(
      DATA.map(item => `/gallery/index/${item}.jpg`)
    )
  },[]);

  return (
    <>
      { isOpen && (
        <Lightbox
          reactModalProps={{ shouldReturnFocusAfterClose: false }}
          enableZoom={false}
          mainSrc={images[selected]}
          nextSrc={images[(selected + 1) % DATA.length]}
          prevSrc={images[(selected + DATA.length - 1) % DATA.length]}
          onCloseRequest={e => { setOpen(false);
          }}
          onMovePrevRequest={() =>
            setSelected((selected + DATA.length - 1) % DATA.length)
          }
          onMoveNextRequest={() =>
            setSelected((selected + 1) % DATA.length)
          }
        />
      )}
      <Layout>
        <Seo title="Galerie" />
        <Section>
          <ContentWrapper>
            <TitleWrapper>
              <Title2>Podívejte se, jak bude rezidence Třešňovka vypadat</Title2>
            </TitleWrapper>
            <Title3 as="h3">Exteriér</Title3>

            <Grid>
              {
                DATA.slice(0,8).map(item => (
                  <ImageWrapper key={item} onClick={() => { setSelected(DATA.indexOf(item)); setOpen(true);}}>
                    <Image src={`/gallery/index/${item}_th.jpg`} alt="Foto"  />
                    <ZoomIconWrapper>
                      <ZoomIcon alt="Ikona" />
                    </ZoomIconWrapper>
                  </ImageWrapper>
                ))
              }
            </Grid>

            <Title3 as="h3">Interiér</Title3>
            <Grid>
              {
                DATA.slice(8, 14).map(item => (
                  <ImageWrapper key={item} onClick={() => { setSelected(DATA.indexOf(item)); setOpen(true);}}>
                    <Image src={`/gallery/index/${item}_th.jpg`} alt="Foto"  />
                    <ZoomIconWrapper>
                      <ZoomIcon alt="Ikona" />
                    </ZoomIconWrapper>
                  </ImageWrapper>
                ))
              }
            </Grid>

            <Title3 as="h3">Výstavba</Title3>
            <Grid>
              {
                DATA.slice(14).map(item => (
                  <ImageWrapper key={item} onClick={() => { setSelected(DATA.indexOf(item)); setOpen(true);}}>
                    <Image src={`/gallery/index/${item}_th.jpg`} alt="Foto"  />
                    <ZoomIconWrapper>
                      <ZoomIcon alt="Ikona" />
                    </ZoomIconWrapper>
                  </ImageWrapper>
                ))
              }
            </Grid>
          </ContentWrapper>
        </Section>
      </Layout>
    </>
  );
}

export default GalleryPage;
